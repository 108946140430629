.drop-zone {
  width: calc(100% - 48px);
  height: 100px;
  border: 2px dashed #aaa;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  transition: background-color 0.3s ease;
  margin: 0 24px 12px 24px;
}

.drop-zone.dragging {
  background-color: #e0e7ff;
  border-color: #4f46e5;
  color: #4f46e5;
}

.drop-zone.invalid-file {
  cursor: not-allowed;
  background-color: #ffe5e5;
  border-color: #ff0000;
  color: #ff0000;
}

.upload-file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pick-file-for-upload {
  cursor: pointer;
  color: var(--main-color);
}

.file-loaded-preview-container {
  margin: 8px 24px 8px 24px;
  padding: 0;
}

.image-file-upload--preview {
  height: 100px;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
}

.button-delete-image-preview {
  cursor: pointer;
  height: 18px;
  margin-bottom: calc(100px - 18px);
}

.pdf-preview {
  /* width: 100%; */
  height: 100px;
  border-radius: 8px;
}
