@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  /* colors */
  --main-color: #7572f4;
  --main-color-accent: #605ecd;
  --main-color-accent-auth: #44428e;
  /* --light-plain-background: green; */
  --light-plain-background: #2e2e2f06;

  --human-interv-color: red;
  --human-interv-color-light: rgba(255, 0, 0, 0.137);
  --ongoing-color: orange;
  --ongoing-color-light: rgba(255, 166, 0, 0.137);
  --paid-color: green;
  --paid-color-light: rgba(0, 128, 0, 0.137);

  /* dimensions */
  --side-panel-width: 264px;
  --main-header-height: 80px;

  /* borders */

  --thin-border: 0.5px solid #1d1f2c17;
  /* --thin-border: 2px solid red; */
  --medium-border: 1px solid #1d1f2c17;
  /* --medium-border: 4px solid red; */
  --light-box-background: #1d1f2c06;
  /* --light-box-background: red; */
}

* {
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  background: #ffffff;
  overflow: hidden;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adapt-to-side-nav {
  margin-left: var(--side-panel-width);
}
.main-content {
  height: calc(100vh - var(--main-header-height));
  /* height: var(--main-header-height); */
  padding: 0;
  margin: 0;
}

.side-navigation__logo {
  width: 24px; /* Adjust logo size */
  height: auto;
  margin-right: 12px;
  fill: var(--main-color);
}

.side-navigation__title {
  font-family: 'Montserrat';
  font-size: 24px; /* Adjust title size */
  margin: 0;
}

.side-navigation__title a, h1 {
  color: rgba(29, 31, 44, 0.8);
  text-decoration: none;
}