.button {
  font: inherit;
  padding: 0.2rem 0.8rem;
  border: 1px solid #49b3b3;
  border-radius: 4px;
  background: #49b3b3;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #3ea0a0;
  border-color: #3ea0a0;
}

.button--inverse {
  background: transparent;
  color: #49b3b3;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #49b3b3;
}

.button--danger {
  background: #036e6e;
  border-color: #036e6e;
}

.button--danger:hover,
.button--danger:active {
  background: #1a9595;
  border-color: #1a9595;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}


.button--insipid {
  background: #f0f0f0; /* Light gray background */
  color: #333; /* Dark text */
  border: 1px solid #ccc; /* Basic border */
}

.button--insipid:hover,
.button--insipid:active {
  background: #e0e0e0; /* Slightly darker gray */
  color: #111; /* Darker text */
}