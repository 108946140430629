.new-client-form {
  margin: 0;
  padding: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.modal-new-client {
  position: fixed;
  width: 40vw;
  /* height: 80vh; */
  top: 10vh;
  left: 30vw;
  /* overflow: scroll; */
}

.form-new-client-main-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-new-client-name-title {
  margin: 0 24px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--Black-500---Primary, #1d1f2c);
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}

.form-new-client-name-input {
  margin: 0 24px;
  display: flex;
  padding: 11px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #f0f1f3;
  background: #f9f9f9;
  color: var(--Black-500---Primary, #1d1f2c);
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}

.form-new-client-error-input {
  margin: 0 40px;
}

.create-new-client-button {
  all: unset;
  display: inline-block;
  cursor: pointer;
  align-self: flex-end;
  display: flex;
  padding: 11px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  background: var(--main-color);
  color: var(--Black-0---Primary, #fff);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
}

.create-new-client-button:hover {
  background-color: var(--main-color-accent);
}

.create-new-client-button:disabled {
  background-color: #a4a4c3;
}

.image-upload-main{
  margin-bottom: 0px;
  margin-top: 0px;
}