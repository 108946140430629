.new-client-title {
  color: #49b3b3;
}

.floating-button {
  position: fixed; /* To position it at the bottom right */
  bottom: 4%; /* Distance from the bottom */
  right: 2%; /* Distance from the right */
  width: 60px; /* Diameter of the circle */
  height: 60px; /* Diameter of the circle */
  border-radius: 50%; /* Make it round */
  background-color: #49b3b3; /* Background color */
  color: white; /* Color of the plus sign */
  font-size: 36px; /* Size of the plus sign */
  border: none; /* Remove default button border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Light shadow */
  cursor: pointer; /* Change cursor on hover */
  display: flex; /* Flex to center the content */
  align-items: center; /* Vertically center the plus sign */
  justify-content: center; /* Horizontally center the plus sign */
}

.floating-button:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Slightly larger shadow on hover */
}

.floating-button:active {
  transform: scale(0.95); /* Scale down slightly on click */
}

.modal-confirm-delete-client {
  position: fixed;
  width: 40vw;
  /* height: 80vh; */
  top: 15vh;
  left: 30vw;
  overflow: hidden;
}

.confirmation-text-delete-popup {
  color: var(--Black-500---Primary, #1d1f2c);
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  padding: 0;
  margin: 0px 20px 0px 20px;
}

.main-client-div{
  display: flex;
  flex-direction: column;
  /* background-color: #49b3b3; */
  height: 100%;

  /* overflow: hidden; */
}

.client-list-header-and-list-container
{
  border: var(--thin-border);
  margin: 0px 56px 20px 20px;
  border-radius: 8px;
  flex-grow: 1;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  /* width: 100%; */
  overflow-x: hidden;

}