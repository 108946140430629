.client-item__link {
  display: block; /* Ensure it behaves like a block element to take full width/height */
  margin: 0;
  padding: 0;
  text-decoration: none; /* Removes underline */
  color: inherit; /* Ensures it inherits the text color */
  transition: background-color 0.1s ease;
  border-radius: 5px;
  width: 26%;
}

.client-item__link:hover .client-item__name {
  font-weight: bold;
}

.client-item {
  padding: 0 8px ;
  margin: 0;
  width: 100%;
  height: 84px;

  display: flex;
  align-items: center;
  border-bottom: var(--thin-border);
}

.client-item__name {
  color: var(--Black-500---Primary, #1d1f2c);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-item__email {
  color: var(--Grey-500---Primary, #667085);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-item__info-container {
  padding: 16px 24px 16px 0px;
}

.client-item__company {
  width: 18%;
  padding: 16px 24px 16px 24px;
  color: var(--Black-500---Primary, #1d1f2c);
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-item__status {
  width: 14%;
  /* color: var(--Secondary-Green-500, #4FBE88); */
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  padding: 16px 24px 16px 24px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-item__start-date {
  width: 15%;
  color: var(--Black-500---Primary, #1d1f2c);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  padding: 16px 24px 16px 24px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-item__price {
  width: 15%;
  color: var(--Black-500---Primary, #1d1f2c);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  padding: 16px 24px 16px 24px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-item__action-container {
  width: 12%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 16px 16px 16px 16px;
  cursor: pointer;
}

.client-item__action-container__bin:hover,
.client-item__action-container__bill:hover,
.client-item__action-container__edit:hover {
  /* fill: black; */
  width: 24px;
  height: 24px;
}

.client-item__action-container__bin {
  width: 20px;
  height: 20px;
  margin-left: 14px;
}

.client-item__action-container__edit {
  width: 20px;
  height: 20px;
}

.client-item__action-container__bill {
  width: 20px;
  height: 20px;
  margin-left: 14px;
}

.ai-state-human-intervention {
  color: red;
}

.ai-state-ongoing {
  color: orange;
}

.ai-state-paid {
  color: green;
}
