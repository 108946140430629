.message-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* max-height: 50vh; */
  /* height: 100px; */
  flex-grow: 1;
  overflow-y: auto;
  /* background-color: #f9f9f9; */
  background-color: white;
  border: var(--medium-border);
  border-radius: 8px; /* Rounded corners */
}

.footer-msg-msg-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-msg-msg-list p {
  margin-left: 10px;

  color: #1d1f2c;
  font-family: Montserrat;
  font-size: 14px;
  /* font-style: normal; */
  /* font-weight: 500; */
  /* line-height: 160%; */
}

.message-date {
  color: #1D1F2C;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
}
