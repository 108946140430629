.conv-header-container {
}

.conv-details-client-info {
  /* text-align: center; */
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.conv-details-client-arrow-left {
  width: 24px;
  height: 24px;
  margin: 6px 12px 6px 0;
  cursor: pointer;
}
.conv-details-client-name {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  color: #1d1f2c;
  font-family: "Montserrat";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 36.4px */
}

.conv-header-recap-container {
  margin: 8px 24px 8px 24px;
  /* padding-bottom: 8px; */
  display: flex;
  justify-content: space-between;
}

.conv-header-folder-state-main-container {
  display: flex;
  width: 35%;
  margin-left: 8px;
  padding: 8px;
  /* justify-content: space-between; */
}
.conv-header-folder-state-left-container {
  display: flex;
  flex-direction: column;
  
}
.conv-header-folder-state-left-container p {
  margin: 0;
  padding: 5px;
  color: #1d1f2c;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 31px;
  /* align-items: center; */
}
.conv-header-folder-state-right-container {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.conv-header-folder-state-right-container p {
  padding: 5px;
  margin: 0;
  /* margin-bottom: 11px; */
  color: #1d1f2c;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 160%; */
  text-align: right !important;
  display: block;
  border-radius: 8px;
  text-align: center; /* Center the text */
  margin: 0 auto; 
  height: 31px;
}

.ai-state-human-intervention-conv-header {
  color: var(--human-interv-color) !important;
  background-color: var(--human-interv-color-light);
}

.ai-state-ongoing-conv-header {
  color: var(--ongoing-color) !important;
  background-color: var(--ongoing-color-light);
}

.ai-state-paid-conv-header {
  color: var(--paid-color) !important;
  background-color: var(--paid-color-light);
}

.conv-header-income-container {
  width: 20%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  background-color: var(--light-box-background);
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
}

.conv-header-income-icon {
  width: 20px;
  height: 20px;
  margin: 4px;
}
.conv-header-income-container h1 {
  margin: 0;
  padding: 0;
  color: #667085;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  /* margin-top: 8px; */
}

.conv-header-income-container p {
  margin: 0;
  padding: 0;

  color: #1d1f2c;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 38.4px */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-bottom: 36px; */
}

.conv-details-client-search-container {
  /* background-color: blue; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 24px 10px 24px;
  border-top: var(--thin-border);
}

.conv-details-client-search-title {
  /* width: 60%; */
  color: #1d1f2c;
  font-family: "Montserrat";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 44.8px */
  padding: 0;
  margin: 0;
}

.conv-details-client-search {
  /* background-color: red; */
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 5px;
}

.conv-details-client-search-input::placeholder {
  font-family: "Montserrat", sans-serif;
  color: var(--Grey-500---Primary, #667085);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
}

.conv-details-client-search-input {
  width: 248px;
  border: 0px solid #ddd;
  border-radius: 20px;
  outline: none;
  margin-left: 12px;
  height: 22px;
  padding-left: 8px;
  background-color: var(--light-plain-background);

}

.conv-details-client-search-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.conv-details-client-msg-icon {
  width: 24px;
  height: 24px;
  margin-right: 24px;

}
.conv-details-client-ellipsis-icon {
  width: 24px;
  height: 24px;
  margin-right: 24px;

}

.conv-header-search-container{
  display: flex;
  align-items: center;
  background-color: var(--light-plain-background);
  padding: 11px 16px 11px 16px;
  border-radius: 8px;
  margin-right: 8px;
}