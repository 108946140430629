.main-header {
    margin-left: var(--side-panel-width);
    height: var(__main-header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-top: 14px;
    padding-left: 24px;
    padding-right:24px ;
    padding-bottom: 14px;
    border-bottom: var(--thin-border);
  }
  
.main-header__search {
  display: flex;
  align-items: center;
  /* flex-grow: 1; */
  background-color: var(--light-plain-background);
  width: 348px;
  padding: 11px 16px 11px 16px;
  border-radius: 8px;
}
.main-header-search-input::placeholder {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: 0.5%;
}

.main-header-search-input {
  width: 248px;
  border: 0px solid #ddd;
  border-radius: 20px;
  outline: none;
  margin-left: 12px;
  height: 22px;
  background-color: var(--light-plain-background);
}

.main-header__icons {
  display: flex;
  align-items: center;
  gap: 1.5rem; /* Espace entre les icônes */
}

.icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Image ronde */
  object-fit: cover; /* S'assure que l'image garde ses proportions */
  cursor: pointer;
}

.main-header-search, .main-header-settings, .main-header-notif{
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.main-header-user-icon {
  cursor: pointer;
  width: 52px;
  height: 52px;
  margin-right: 28px;
}