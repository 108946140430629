.mail-icon {
  width: 20px;
  height: 20px;
  margin: 2px;
}

.arrow-toggle-show {
  width: 20px;
  height: 18px;
  margin-left: 5px;
}
