.side-nav {
  width: var(--side-panel-width);
  height: 100vh; /* Full height of the viewport */
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--light-plain-background);
  
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left:20px ;
  padding-right:24px ;
  padding-top:26px ;
  border-right: var(--thin-border);
}

.nav-links {
  list-style: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column; /* Ensure vertical alignment */
}

