.conv-details-container {
  padding: 0px 28px 10px 30px;
  background-color: #ffffff;
  border: 0px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* margin: ; */
  display: flex;
  flex-direction: column;
  height:calc(100vh - 80px); /*80px is the height of the header !*/
  overflow: hidden;
}

.no-conversation-text{
  text-align: center;
  margin-top: 80px;
}