.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  /* background: white; */
  background: #49b3b3;  
}

.main-navigation__title {
  /* color: white; */
  color: #49b3b3;
  
}

.main-navigation__title a {
  text-decoration: none;
  /* color: white; */
  color: #49b3b3;

}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

.main-navigation__menu-btn {
  display: none;
}

.main-navigation__header-nav {
  display: block;
}

.side-navigation-title__container {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.side-navigation__nav-links {
  display: flex;
  flex-direction: column;
  margin-top: 58px;
  height: 100%;
}
