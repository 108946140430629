.message-container {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
}

.sent {
  display: flex;
  flex-direction: column;
  /* background-color: green; */
  max-width: 80%;
  margin-left: auto;
}

.received {
  /* background-color: yellow; */
  max-width: 80%;
}

.msg-title-hour-txt {
  font-size: 12px !important;
  margin-left: 0;
  padding-left: 0;
  font-style: normal !important;
  font-weight: 400 !important;
}

.msg-content-box {
  border: var(--thin-border);
  border-radius: 8px;
  background-color: var(--light-box-background);
  padding: 12px;
  margin-top: 0px;
  /* margin-right: 5px; */
}

.msg-content-box-voice-msg {
  border: var(--thin-border);
  border-radius: 8px;
  /* background-color: brown; */
  padding: 8px;
  margin-top: 0px;
  margin-bottom: 3px;
  width: max-content;
}

.msg-content-box-voice-msg-container {
  display: flex;
  align-items: center;
  justify-content: end;
  /* margin-right: 8px; */
  /* background-color: aquamarine; */
}

.mail-subject-txt {
  padding: 0;
  /* margin: 20px 0 0 20px; */
  margin: 0;
  color: #1d1f2c;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 15.6px */
}

.mail-content-txt {
  padding: 0;
  /* margin: 10px 0 0 20px; */
  margin: 0;
  color: #1d1f2c;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
}

.mail-content-txt p {
  padding: 0;
  /* margin: 10px 0 0 20px; */
  margin: 0;
}

.mail-title-container {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.sent > .mail-title-container {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 8px;
}

.mail-title-container p {
  margin: 0;

  /* margin-left: 12px; */
  padding: 0;

  color: #1d1f2c;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.toggle-content-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-top: 5px;
}

.toggle-content-container p {
  color: #1d1f2c;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 15.6px */
  margin-left: auto;
  margin-top: 0px;
  /* margin-top: 5px; */
  margin-bottom: 0;
}
