.clients-list {
  list-style: none;
  margin: 0 24px 0 24px;
  padding: 0;
  display: flex;
  /* justify-content: space-around; */
  /* flex-wrap: wrap; */
  /* height: 100%; */
  flex-grow: 1;
  /* overflow-x: hidden; */
  overflow-y: scroll;
  flex-direction: column;
  
}

.client-list__header {
  padding: 0 8px;
  width: 100%;
  height: 56px;
  margin: 0;
  background-color: var(--light-plain-background);
  border-radius: 8px;
  margin-top: 18px;

}

.client-list__header span{
  display: inline-block;
  color: var(--Grey-500---Primary, #667085);
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; 
  padding: 16px 0 16px 0;
}

.client-list__header span:not(:first-child) {
  padding-left: 24px;
}

/* Apply right padding to all spans except the last one */
.client-list__header span:not(:last-child) {
  padding-right: 24px;
}

.client-list__header__name {
  width:26%;
}
.client-list__company {
  width:18%;
}
.client-list__status {
  width:14%;
}
.client-list__start_date {
  width:15%;
  /* padding-left: 2px !important; */
  /* padding-right: 0px !important; */
}
.client-list__price {
  width:15%;
}

.client-list__action {
  width:12%;
}
