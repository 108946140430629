.client-list-header {
  /* background-color: #f5f5f5; */
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 56px;
  flex-shrink: 0; 

}

/* Row containing the title, subtitle, and button */
.client-list-header__top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.client-list-header__text {
  display: flex;
  flex-direction: column;
}

.client-list-header__title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.client-list-header__subtitle {
  color: var(--Grey-500---Primary, #667085);
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

/* Button stays to the right and does not take full width */
.client-list-header__button {
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Prevents the button from stretching or wrapping */
  display: flex;
  padding: 11px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--main-color);
  

  color: var(--Black-0---Primary, #fff);
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
  outline: none; /* Removes the default outline */
  border: none;

  align-self: flex-end;
}
.client-list-header__button:focus {
  outline: none; /* Ensures no outline appears when button is focused */
}

.plus-sign-add-client {
  font-size: 20px;
  font-weight: 700;
}

.client-list-header__button:hover {
  background-color: var(--main-color-accent);
}

/* Controls section */
.client-list-header__controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 24px;
  margin-right: 24px;
  height: 106px;
}

.client-list-header__search {
  display: flex;
  align-items: center;
  /* border: 1px solid #ddd; */
  border : var(--thin-border);
  padding: 5px;
  padding-left: 10px;
  height: 50px;
  margin-top: 28px;
  border-radius: 8px;

}

.client-list-header__filter-button span,
.client-list-header__date-button span {
  margin-right: 0.5rem;
}

.client-list-header-search {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.client-list-header-search-input::placeholder {
  font-family: "Montserrat", sans-serif;
  color: var(--Grey-500---Primary, #667085);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
}

.client-list-header-search-input {
  width: 248px;
  border: 0px solid #ddd;
  border-radius: 20px;
  outline: none;
  margin-left: 12px;
  height: 22px;
}

.client-list-header__sort {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: auto;
  cursor: pointer;
  border : var(--thin-border);
  height: 50px;
  margin-top: 28px;
  margin-right: 8px;
  border-radius: 8px;
}

.client-list-header__sort__icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.client-list-header__sort__text {
  color: var(--Grey-500---Primary, #667085);
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  margin-left: 8px;
}
