.modal-general {
  padding: 32px 20px;
  /* border-radius: 12px; */
  background: var(--Black-0---Primary, #fff);
  overflow: auto;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 15;
  width: 500px;
  /* margin-left: calc((100vw - var(--side-panel-width)) / 2) ; */
  /* margin-left: calc(var(--side-panel-width)); */
}

.modal__header {
  width: 100%;
  height: 70px;
  padding: 20px 24px;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal__header p {
  margin: 0;
  padding: 0;
  color: var(--Black-500---Primary, #1d1f2c);
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 31.2px */
}

.modal__header__cross {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.modal__footer {
  position: absolute;
  bottom: 0; /* Aligns the child div to the bottom */
  right: 0;
  margin-right: 20px;
  margin-bottom: 32px;
}

.modal__footer_delete_container {
  /* width: 100%; */
  display: flex;
 
  margin: 32px 20px 0px 20px;
}
.modal__footer__cancel {
  all: unset;
  display: inline-block;
  cursor: pointer;

  display: flex;
  padding: 11px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  color: #1d1f2c;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-radius: 6px;
  margin: 0;

  line-height: 130%; /* 18.2px */
  background-color: white;
}
.modal__footer__cancel:hover{
  background-color: rgb(226, 224, 224);

}

.modal__footer__delete {
  all: unset;
  display: inline-block;
  cursor: pointer;

  display: flex;
  display: flex;
  padding: 11px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  background: #f04a4a;
  color: var(--Black-0---Primary, #fff);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
  margin: 0;
  margin-left: 10px;
}

.modal__footer__delete:hover{
  background-color: #af3434;

}