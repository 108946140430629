.header__calendar {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
  border: var(--thin-border);
  height: 50px;
  margin-top: 28px;

  border-radius: 8px;
}
.header__calendar__icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.header__calendar__text {
  color: var(--Grey-500---Primary, #667085);
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  margin-left: 8px;
}
.header__calendar__arrow-down-icon {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  margin-left: 12px;
}
