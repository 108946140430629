.audio-player {
  display: flex;
  gap: 12px;
  align-items: center;
  /* margin: 20px 0 0 8px; */
  margin: 0;
}


.waveform-slider {
  display: flex;
  align-items: center;
  gap: 4px; /* Space between bars */
  cursor: pointer;
  user-select: none;
}

.waveform-bar {
  width: 2px;
  background-color: rgba(0, 0, 0, 0.4); /* Base color with 50% opacity */
  border-radius: 1px; /* Rounded corners */
  transition: opacity 0.3s ease; /* Smooth transition for next bar */
}

.audio-duration {
  font-size: 14px;
  color: #333;
}


.play-button {
  cursor: pointer; /* Make the play/pause button a pointer */
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.icon-button {
  width: 32px;
  height: 32px;
  cursor: pointer; /* Make the icon itself a pointer */
}
