/* Import the Montserrat font */

.nav-links {
  padding: 0;
  margin: 0;
}

.nav-links li {
  width: 100%; /* Full width for each item */
  margin: 0;
}

.nav-links a, .nav-links button {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  color: #1D1F2CCC;
  fill: #1D1F2CCC;
  transition: color 0.3s ease;
  padding: 12px 12px;
}

/* Hover state: change text and logo color to black */
.nav-links a:hover, .nav-links button:hover {
  color: #000000;
  fill: #000000;
}

/* .nav-links a{
  padding: px 5px 5px 5px;
} */
.nav-links a.active {
  color: var(--main-color);
  fill: var(--main-color);
  background-color: white;
  border-radius: 5px;
  border: var(--thin-border);
}

.nav-links button {
  background: none;
  border: none;
  cursor: pointer;
  color: #1D1F2CCC;
  font-family: 'Montserrat', sans-serif;
}

.nav-links-txt
{
  margin-left: 12px;
}

.deconnection-container{
  color: #F04A4A;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 24px;
}

.deconnection-container span{
  margin-left: 10px;
} 

.nav-links-icon {
  height: 22px;
  width: auto;
}